import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

export default function Ads({ className }) {
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {}
  }, []);

  const defaultClassName = 'adsbygoogle ';
  const combinedClassName = className
    ? defaultClassName + className
    : defaultClassName;

  return (
    <ins
      className={'block bg-gray-100 ' + combinedClassName}
      data-ad-client="ca-pub-7675288227309864"
      data-ad-slot="8297264409"
      data-adtest="on"
    ></ins>
  );
}

Ads.propTypes = {
  className: PropTypes.string,
};
